import React, { HTMLProps, useEffect, useState } from 'react'
import Row from '../Layout/Row'
import { tw } from '@minupalaniappan/brise'
import styled from 'styled-components'
import { ContainerProps } from '../../types'
import { PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Popover } from 'react-tiny-popover'
import TableFilterBlock from './TableFilterBlock'
import { CustomFilter, RecordData } from './PipelineTable'
import styles from './tableFilters.module.css'
import ListViewOptionsMenu from './ListViewOptionsMenu'
import FilterCombinationDropdown from './FilterCombinationDropdown'
import {
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import HighlightsMenu from './HighlightsMenu'
import { SortingState } from '@tanstack/react-table'
import Api from '../../lib/api'
import pluralize from 'pluralize'
import { FaChevronDown } from 'react-icons/fa6'
import ListViewUpdateCreateMenu from './ListViewUpdateCreateMenu'
import ShadedBackground from '../ShadedBackground'

type TableFilterCollectionProps = {
  records: Array<RecordData>
  listViews: Array<any>
  teams: Array<any>
  teamsToColors: any
  onSaveListView: (
    name: string,
    isDefault: boolean,
    isCreate: boolean,
    remove: boolean,
    isOrgWide: boolean,
    teamIds: any[],
  ) => any
  selectedListViewId: string | undefined
  selected: Array<any>
  updateListViews: (listViews: Array<any>, fields: Array<any>) => void
  user: any
  users: any[]
} & TableFilterResultsProps &
  TableFilterSegmentCollectionProps &
  TableHighlightsProps &
  TableSortingProps

type TableFilterResultsProps = {
  objectType: string
  foundItems: number
  isFetchingMore: boolean
}

const FoundItems = tw.div<ContainerProps>`
  !text-gray-500
  text-sm
  font-normal
  whitespace-nowrap	
`

const FoundItemsBolded = tw.span<ContainerProps>`
  font-semibold
  text-black
`

export const StartAddingFiltersButton = styled.div`
  padding-left: 14px;
  padding-right: 14px;
  font-size: 12px;
  margin-top: 1px;
`

export const AddFilterButtonInModal = tw.div<HTMLProps<HTMLDivElement>>`
              cursor-pointer
              rounded-[5.5px]
              bg-black
              text-center
              text-white
              hover:bg-[#474747]
              px-3.5
              py-1.5
              text-xs
              font-medium
              ${props =>
                props.disabled ? `opacity-50 pointer-events-none` : ''}
              `

export const DeleteFilterButtonInModal = tw.div<HTMLProps<HTMLDivElement>>`
              cursor-pointer
              rounded-[5.5px]
              text-center
              text-black
              border
              w-[160px]
              bg-[#ebebeb]  // Background color from DeleteButton
              text-[#1a202c]  // Text color from DeleteButton
              px-3.5
              py-1.5
              text-xs
              font-medium
              hover:bg-[#fafafa]
              flex  // Use flex to align items horizontally
              items-center  // Center the icon and text vertically
`

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
`

export type TableFilterSegmentProps = {
  user: any
  teams: any[]
  users: any[]
  filters: CustomFilter[]
  filter: CustomFilter
  filterIndex: number
  key?: string
  forwardKey?: string
  label?: string
  onRemove?: Function
  setFilters: Function
  fields: Array<any>
  filterJoin?: string
  objectType?: string
  displaySavedFilterNameOnly?: boolean
  savedFilters: any[]
  onSetSavedFilters: Function
  onSaveListView?: (
    name: string,
    isDefault: boolean,
    isCreate: boolean,
    remove: boolean,
    isOrgWide: boolean,
    teamIds: any[],
  ) => any
  selectedListViewId?: string | undefined
  listViews?: Array<any>
} & ContainerProps

type TableFilterSegmentCollectionProps = {
  user: any
  users: any[]
  fields: any[]
  filters: CustomFilter[]
  setFilters: Function
  onClearFilters: () => void
  onSaveFilters: () => void
  onRemove: Function
  filterJoin: 'and' | 'or' | string
  setFilterJoin: (value: 'and' | 'or' | string) => void
  teams: Array<any>
  selectedListViewId: string | undefined
  listViews: Array<any>
  objectType: string
  savedFilters: any[]
  onSetSavedFilters: Function
  onSaveListView?: (
    name: string,
    isDefault: boolean,
    isCreate: boolean,
    remove: boolean,
    isOrgWide: boolean,
    teamIds: any[],
  ) => any
} & ContainerProps

type TableHighlightsProps = {
  highlights: CustomFilter[]
  setHighlights: Function
  onRemoveHighlights: Function
}

type TableSortingProps = {
  sorting: SortingState
}

export type TableFilterProps = {
  isLast?: boolean
  isFirst?: boolean
  isIsolated?: boolean
} & ContainerProps

const TableFilterCell = tw.div<TableFilterProps>`
  ${props => (props.isFirst ? 'rounded-bl-md rounded-tl-md' : '')}
  ${props => (props.isLast ? 'rounded-tr-md rounded-br-md p-1' : '')}
  ${props => (props.isIsolated ? 'rounded-md p-1' : '')}
  cursor-pointer
  bg-[#F8F8F8]
  !h-[24px]
  border-r border-r-[#F8F8F8] border-r-2
  pr-1
  flex
  items-center
  text-[13px]
  m-0
  p-0
`
const SaveButton = tw.div<HTMLProps<HTMLDivElement>>`
          cursor-pointer
  rounded-[5.5px]
  bg-black
  text-center
  text-white
  hover:bg-[#474747]
  px-3.5
  py-1.5
  text-xs
  font-medium
          ${props => (props.disabled ? `opacity-50 pointer-events-none` : '')}
          `

function formatDate(date: any) {
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day

  return `${month}/${day}/${year}`
}

function modalTitle(objectName: string) {
  const pluralizedName = pluralize(objectName, 2)

  const vowels = ['a', 'e', 'i', 'o', 'u']
  let article = 'a'
  if (vowels.includes(objectName[0].toLowerCase())) {
    article = 'an'
  }

  return `Save you current filters as ${article} ${pluralizedName} filter`
}

export function getComparatorChipString(comparator: string, value?: string) {
  switch (comparator) {
    case '=':
      return 'is'
    case '!=':
      return 'is not'
    case 'contains':
      return 'includes'
    case 'last_n_days':
      return `in last ${value} days`
    case 'next_n_days':
      return `in next ${value} days`
    case 'last_n_months':
      return `in last ${value} months`
    case 'next_n_months':
      return `in next ${value} months`
    case 'last_n_quarters':
      return `in last ${value} quarters`
    case 'next_n_quarters':
      return `in next ${value} quarters`
    case 'is_not_blank':
      return 'is not'
    case 'greater_than_percent':
      return `is more than ${value}% of`
    case 'less_than_percent':
      return `is less than ${value}% of`
    case 'today':
      return `is today`
    case 'not_today':
      return `is not today`
    case 'before_today':
      return `is before today`
    case 'after_today':
      return `is after today`
    case 'this_week':
      return 'is within this week'
    case 'this_month':
      return `is within this month`
    case 'this_quarter':
      return `is within this quarter`
    case 'this_year':
      return `is within this year`
    case 'next_month':
      return `is within next month`
    case 'next_quarter':
      return `is within next quarter`
    case 'more_than_n_days_ago':
      return `is more than ${value} days ago`
    case 'less_than_n_days_ago':
      return `is less than ${value} days ago`
    default:
      return comparator
  }
}

export function formatConditionValue(value: any) {
  if (value instanceof Date) {
    return formatDate(value)
  }

  if (value?.length === 0) {
    return 'BLANK'
  }

  if (value === 'true' || value === 'false') {
    return value === 'true' ? 'Yes' : 'No'
  }

  return value
}

export function getComparatorFilterStringFromSymbol(comparator: string) {
  switch (comparator) {
    case '=':
      return 'equals'
    case '!=':
      return 'does_not_equal'
    case '>':
      return 'greater_than'
    case '<':
      return 'less_than'
    default:
      return comparator
  }
}

export const TableFilterSegment = (props: TableFilterSegmentProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isAddingFilter, setIsAddingFilter] = useState(false)
  const [newFilter, setNewFilter] = useState<CustomFilter | null>(null)
  const [editedFilters, setEditedFilters] = useState<CustomFilter[]>([])
  const [isUpdateButtonPressed, setIsUpdateButtonPressed] = useState(false)
  const [isSavedFilterListView, setIsSavedFilterListView] = useState(false)
  const toast = useToast()

  const randomId = Math.floor(Math.random() * 500) + 1

  const handleAddFilterClick = () => {
    // todo: add saved filter id
    setIsAddingFilter(true)
    setNewFilter({
      key: '',
      label: 'New Filter',
      isSavedFilter: true,
      conditions: [
        {
          comparator: '=',
          value: '',
          comparativeFieldName: undefined,
        },
      ], // Placeholder for conditions
      operator: 'and',
      id: randomId, // Generate a unique id
      savedFilterId: props.filter.id,
    })
    // setIsAddingFilter(false);
  }

  const handleSaveNewFilter = async (filter: CustomFilter) => {
    const savedFilter = props.savedFilters.find(
      savedFilter => savedFilter.id === props.filter.id,
    )
    if (savedFilter) {
      // Update the saved filter with the new filter details
      const updatedSavedFilters = props.savedFilters.map(savedFilterItem => {
        if (savedFilterItem.id === savedFilter.id) {
          return {
            ...savedFilterItem,
            filters: [...savedFilterItem.filters, filter],
          }
        }
        return savedFilterItem
      })

      // Update the saved filters and filters in the frontend
      props.onSetSavedFilters(updatedSavedFilters)
      props.setFilters([
        ...props.filters,
        { ...filter, isSavedFilter: true, savedFilterId: savedFilter.id },
      ])
      // Update the backend
      try {
        const response = await Api.pipelineSavedFilters.update(savedFilter.id, {
          filters: [...savedFilter.filters, filter],
        })

        // Check if the response contains an error
        if ('error' in response) {
          toast({
            variant: 'subtle',
            title: 'Error updating saved filter',
            description: `${response.error}`,
            position: 'bottom-right',
            status: 'error',
            duration: 10000,
            isClosable: false,
            containerStyle: {
              marginBottom: '40px',
              marginRight: '40px',
            },
          })
          return false
        } else {
          // If no error, show success toast
          toast({
            variant: 'subtle',
            title: 'Saved filter updated',
            description: '',
            position: 'bottom-right',
            status: 'success',
            duration: 5000,
            isClosable: false,
            containerStyle: {
              marginBottom: '40px',
              marginRight: '40px',
            },
          })
        }
      } catch (error) {
        console.error('Failed to update saved filter:', error)
      }

      setIsAddingFilter(false)

      setNewFilter(null)
    }
  }

  const handleFilterChange = (updatedFilter: CustomFilter) => {
    setEditedFilters(prevFilters => {
      // Find if the filter already exists in the previous filters
      const existingFilterIndex = prevFilters.findIndex(
        filter => filter.id === updatedFilter.id,
      )

      if (existingFilterIndex !== -1) {
        // Replace the existing filter with the updated one
        return prevFilters.map((filter, index) => {
          if (index === existingFilterIndex) {
            return updatedFilter
          }
          return filter
        })
      } else {
        // Add the updated filter to the list if it doesn't exist
        return [...prevFilters, updatedFilter]
      }
    })
  }

  const handleUpdateButtonClick = async () => {
    const updatedFilters = props.filters.filter(
      filter => !filter.removeFromModal,
    )

    // Remove filters from props.savedFilters where removeFromModal is true
    const updatedSavedFilters = props.savedFilters.map(savedFilter => ({
      ...savedFilter,
      filters: savedFilter.filters.filter(
        (filter: any) => !filter.removeFromModal,
      ),
    }))

    const savedFiltersToUpdate = props.savedFilters.filter(savedFilter =>
      savedFilter.filters.some((filter: any) => filter.removeFromModal),
    )

    // Update the state with the new filters
    props.setFilters(updatedFilters)
    props.onSetSavedFilters(updatedSavedFilters)

    // Update the saved filters on the backend
    try {
      await Promise.all(
        savedFiltersToUpdate.map(async savedFilter => {
          const updatedFilter = updatedSavedFilters.find(
            filter => filter.id === savedFilter.id,
          )
          if (updatedFilter) {
            await Api.pipelineSavedFilters.update(updatedFilter.id, {
              filters: updatedFilter.filters,
            })
          }
        }),
      )
    } catch (error) {
      console.error('Failed to update saved filter on the backend:', error)
    }
    setIsUpdateButtonPressed(true)
  }

  const handleDeleteSavedFilterButtonClick = async () => {
    const savedFilterId = props.filter.id

    // Find the saved filter in props.savedFilters
    const savedFilter = props.savedFilters.find(
      filter => filter.id === savedFilterId,
    )

    if (!savedFilter) {
      console.error('Saved filter not found')
      return
    }

    // Remove the saved filter on the backend
    try {
      await Api.pipelineSavedFilters.destroy(savedFilterId)
    } catch (error) {
      console.error('Failed to delete saved filter from backend:', error)
      return
    }

    // Update the frontend state
    const updatedSavedFilters = props.savedFilters.filter(
      filter => filter.id !== savedFilterId,
    )

    const updatedFilters = props.filters.filter(
      filter => !savedFilter.filters.includes(filter),
    )

    // Assuming you have a way to update props, like a prop function called `setFilters` or similar
    props.onSetSavedFilters(updatedSavedFilters)
    props.setFilters(updatedFilters)
  }

  const updatingFiltersBe = async () => {
    const isFilterInSavedFilters = props.savedFilters.some(
      savedFilter => savedFilter.id === props.filter.id,
    )
    if (isFilterInSavedFilters) {
      const currSavedFilter = props.savedFilters.find(
        savedFilter => savedFilter.id === props.filter.id,
      )

      if (currSavedFilter.filters.length <= editedFilters.length) {
        if (!isSavedFilterListView) {
          try {
            await Api.pipelineSavedFilters.update(currSavedFilter.id, {
              filters: editedFilters,
            })
          } catch (error) {
            console.error('failed to update filter', error)
          }
        }
        const updatedFilters = props.filters.map((filter: any) => {
          // Check if the filter exists in editedFilters by matching their id
          const matchingEditedFilter = editedFilters.find(
            (editedFilter: any) => editedFilter.id === filter.id,
          )

          // If a matching filter is found in editedFilters, replace it; otherwise, keep the original filter
          return matchingEditedFilter ? matchingEditedFilter : filter
        })

        // Set the updated filters back into state
        props.setFilters(updatedFilters)

        setEditedFilters([])
        const updatedSavedFilter = {
          ...currSavedFilter,
          filters: editedFilters,
        }

        // Replace the updated filter in the saved filters array
        const updatedSavedFilters = props.savedFilters.map(savedFilter =>
          savedFilter.id === updatedSavedFilter.id
            ? updatedSavedFilter
            : savedFilter,
        )

        props.onSetSavedFilters(updatedSavedFilters)
        setIsUpdateButtonPressed(false)
        setIsPopoverOpen(false)
        setIsSavedFilterListView(false)
      }
    }

    setIsPopoverOpen(false)
    setIsAddingFilter(false)
  }

  const isFilterInSavedFilters = props.savedFilters.some(
    savedFilter => savedFilter.id === props.filter.id,
  )
  const currSavedFilter = props.savedFilters.find(
    savedFilter => savedFilter.id === props.filter.id,
  )
  if (
    isFilterInSavedFilters &&
    currSavedFilter.filters.length <= editedFilters.length
  ) {
    updatingFiltersBe()
  }

  const filterBoxStyle = props.filter.isSavedFilter
    ? { boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' } // Add shadow for saved filters
    : {}

  const handleUpdateFiltersOnListView = async () => {
    // Logic to update filters on the list view
    setIsSavedFilterListView(true)
    await handleUpdateButtonClick()
  }

  const handleUpdateSavedFilter = async () => {
    await handleUpdateButtonClick() // Use your existing handleUpdateButtonClick function to update saved filters
  }

  const handleSetIsAddingFilter = () => {
    setIsAddingFilter(!isAddingFilter)
  }

  return (
    <Row y='center'>
      <Popover
        {...{
          isOpen: isPopoverOpen,
          padding: 10,
          containerClassName: 'z-10',
          transformMode: 'relative',
          positions: [(props.filterIndex || 0) > 1 ? 'bottom' : 'right'],
          clickOutsideCapture: true,
          onClickOutside: e => {
            e.preventDefault()

            setIsPopoverOpen(false)
          },
          content: (
            <>
              {props.filter.isSavedFilter || props.filter.is_saved_filter ? (
                // Render saved filter container and content only for saved filters
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '20px',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                    maxWidth: '1000px',
                    height: 'auto',
                    marginTop: '200px',
                    zIndex: 0,
                    overflowY: 'auto',
                    maxHeight: '70vh',
                  }}
                >
                  {/* Render all filters inside savedFilter in popover */}
                  <div
                    style={{
                      flex: '1',
                      marginBottom: '20px',
                    }}
                  >
                    {props.savedFilters
                      .find(savedFilter => savedFilter.id === props.filter.id)
                      ?.filters.filter(
                        (savedFilterDetail: any) =>
                          !savedFilterDetail.removeFromModal,
                      )
                      .map((savedFilterDetail: CustomFilter, index: any) => {
                        return (
                          <TableFilterBlock
                            key={`saved-filter-detail-${index}`}
                            {...{
                              user: props.user,
                              users: props.users,
                              teams: props.teams,
                              fields: props.fields,
                              filters: [savedFilterDetail], // Pass each filter in the saved filter
                              setFilters: props.setFilters,
                              selectedFilter: savedFilterDetail,
                              objectType: props.objectType,
                              onSubmit: () => setIsPopoverOpen(false),
                              savedFilters: props.savedFilters,
                              onSetSavedFilters: props.onSetSavedFilters,
                              selectedFilterIndex: props.filterIndex,
                            }}
                            isUpdateButtonPressed={isUpdateButtonPressed}
                            setIsUpdateButtonPressed={setIsUpdateButtonPressed}
                            editedFilters={editedFilters}
                            setEditedFilters={handleFilterChange}
                            allFilters={props.filters}
                          />
                        )
                      })}
                  </div>
                  {/* Render form to add a new filter if adding */}

                  {isAddingFilter && newFilter && (
                    <>
                      <div style={{ paddingBottom: '10px' }}>
                        <TableFilterBlock
                          {...{
                            user: props.user,
                            users: props.users,
                            teams: props.teams,
                            fields: props.fields,
                            filters: [newFilter], // Pass the new filter being added
                            setFilters: (updatedFilters: any[]) => {
                              // Update new filter state when modified
                              setNewFilter(updatedFilters[1])
                              handleSaveNewFilter(updatedFilters[1])
                            },
                            selectedFilter: newFilter,
                            objectType: props.objectType,
                            onSubmit: () => {
                              setIsPopoverOpen(false)
                            },
                            savedFilters: props.savedFilters,
                            onSetSavedFilters: props.onSetSavedFilters,
                            selectedFilterIndex: props.filterIndex,
                          }}
                          isUpdateButtonPressed={isUpdateButtonPressed}
                          setIsUpdateButtonPressed={setIsUpdateButtonPressed}
                          editedFilters={editedFilters}
                          setEditedFilters={handleFilterChange}
                          isAddingFilter={isAddingFilter}
                          setIsAddingFilter={handleSetIsAddingFilter}
                        />
                      </div>
                    </>
                  )}

                  <div
                    style={{
                      marginTop: '-20px',
                      textAlign: 'right',
                      position: 'relative',
                      bottom: '0',
                      backgroundColor: 'white',
                      padding: '10px 0',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '10px', // Adjust the gap between the buttons if needed
                    }}
                  >
                    <div
                      style={{
                        marginTop: '10px',
                        textAlign: 'right',
                        position: 'relative',
                        bottom: '0',
                        backgroundColor: 'white',
                        padding: '10px 0',
                      }}
                    >
                      <DeleteFilterButtonInModal
                        onClick={handleDeleteSavedFilterButtonClick}
                      >
                        <TrashIcon
                          className='w-[15px] h-[15px]'
                          style={{ marginRight: '5px' }}
                        ></TrashIcon>
                        {'Delete Saved Filter'}
                      </DeleteFilterButtonInModal>
                    </div>
                    {/* Add Filter button */}
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px', // Adds space between the two buttons
                      }}
                    >
                      {!isAddingFilter && (
                        <div
                          style={{
                            marginTop: '10px',
                            textAlign: 'right',
                            position: 'relative',
                            bottom: '0',
                            backgroundColor: 'white',
                            padding: '10px 0',
                          }}
                        >
                          <AddFilterButtonInModal
                            onClick={handleAddFilterClick}
                          >
                            Add Filter
                          </AddFilterButtonInModal>
                        </div>
                      )}

                      {/* <div
                  style={{
                    marginTop: '10px',
                    textAlign: 'right',
                    position: 'relative',
                    bottom: '0',
                    backgroundColor: 'white',
                    padding: '10px 0',
                  }}
                >
                  <AddFilterButtonInModal onClick={handleUpdateButtonClick}>
                    Update saved filter
                  </AddFilterButtonInModal>
                </div> */}

                      <div
                        style={{
                          marginTop: '19px',
                          textAlign: 'right',
                          // position: 'relative',
                          // bottom: '0',
                          backgroundColor: 'white',
                          // padding: '10px 0',
                        }}
                      >
                        <Menu>
                          <MenuButton
                            as={Button}
                            size='sm'
                            variant='outline'
                            backgroundColor='white'
                            rightIcon={<FaChevronDown />}
                            sx={{
                              cursor: 'pointer',
                              borderRadius: '5.5px',
                              backgroundColor: 'black',
                              color: 'white',
                              height: '30px',
                              textAlign: 'center',
                              px: '3.5',
                              py: '1.5',
                              fontSize: 'xs',
                              fontWeight: 'medium',
                              _hover: {
                                backgroundColor: '#474747',
                              },
                              _disabled: {
                                opacity: 0.5,
                                pointerEvents: 'none',
                              },
                            }}
                          >
                            Update
                          </MenuButton>
                          <MenuList zIndex={100}>
                            <MenuItem onClick={handleUpdateFiltersOnListView}>
                              Update Filters
                            </MenuItem>
                            <MenuItem onClick={handleUpdateSavedFilter}>
                              Update Saved Filter
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // Render normal individual filter in popover
                <>
                  <TableFilterBlock
                    {...{
                      user: props.user,
                      users: props.users,
                      teams: props.teams,
                      fields: props.fields,
                      filters: props.filters,
                      setFilters: props.setFilters,
                      selectedFilter: props.filter,
                      objectType: props.objectType,
                      onSubmit: () => setIsPopoverOpen(false),
                      savedFilters: props.savedFilters,
                      onSetSavedFilters: props.onSetSavedFilters,
                      selectedFilterIndex: props.filterIndex,
                    }}
                    isUpdateButtonPressed={isUpdateButtonPressed}
                    setIsUpdateButtonPressed={setIsUpdateButtonPressed}
                    editedFilters={editedFilters}
                    setEditedFilters={handleFilterChange}
                  />
                </>
              )}
            </>
          ),
        }}
      >
        <div
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          style={{ display: 'flex' }}
        >
          {(props.filter.isSavedFilter || props.filter.is_saved_filter) &&
          typeof props.filter.id === 'string' ? (
            <>
              <TableFilterCell className='pl-2 whitespace-nowrap'>
                {props.filter.label}
              </TableFilterCell>
            </>
          ) : (
            !props.filter.isSavedFilter &&
            !props.filter.is_saved_filter &&
            props.filter.conditions.map((condition, index) => (
              <React.Fragment key={index}>
                <TableFilterCell
                  isFirst={index === 0}
                  className={
                    props.filter.conditions.length > 0 && index != 0
                      ? 'pl-0 whitespace-nowrap'
                      : 'pl-2 whitespace-nowrap'
                  }
                >
                  {props.filterJoin &&
                    props.filterJoin != 'and' &&
                    props.filterJoin != 'or' &&
                    index === 0 && (
                      <p
                        style={{
                          marginRight: '2px',
                          fontFamily: 'monospace',
                          color: '#858585',
                        }}
                      >
                        {`[${(props.filterIndex + 1).toString()}]`}
                      </p>
                    )}
                  {props?.filter?.key === 'OwnerId'
                    ? 'Owner'
                    : props?.filter?.key?.includes('teamOwned')
                    ? `Owned by ${
                        props.teams?.find(
                          (team: any) =>
                            team.id ===
                            props?.filter?.key.replace('teamOwned-', ''),
                        )?.name
                      } members`
                    : props.filter.label}
                </TableFilterCell>
                <TableFilterCell
                  style={{
                    color: '#858585',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getComparatorChipString(
                    condition.comparator,
                    condition.value,
                  )}
                </TableFilterCell>
                {![
                  'last_n_days',
                  'last_n_months',
                  'last_n_quarters',
                  'next_n_days',
                  'next_n_months',
                  'next_n_quarters',
                  'today',
                  'not_today',
                  'before_today',
                  'after_today',
                  'this_week',
                  'this_month',
                  'this_quarter',
                  'this_year',
                  'next_month',
                  'next_quarter',
                  'more_than_n_days_ago',
                  'less_than_n_days_ago',
                ].includes(condition.comparator) && (
                  <TableFilterCell className='whitespace-nowrap'>
                    {['greater_than_percent', 'less_than_percent'].includes(
                      condition.comparator,
                    )
                      ? props.fields?.find(
                          (field: any) =>
                            field.name === condition.comparativeFieldName,
                        )?.label || condition.comparativeFieldName
                      : props?.filter?.key === 'RecordTypeId'
                      ? props.fields
                          ?.find((field: any) => field.name === 'RecordTypeId')
                          ?.picklist_values?.find(
                            (picklistValue: any) =>
                              picklistValue.value === condition.value,
                          )?.label || formatConditionValue(condition.value)
                      : props?.filter?.key === 'OwnerId'
                      ? props?.users?.find(
                          (user: any) => user.id === condition.value,
                        )?.name || condition.value
                      : props?.filter?.key?.includes('teamOwned')
                      ? ''
                      : formatConditionValue(condition.value)}
                  </TableFilterCell>
                )}
                {props.filter.conditions.length > 0 &&
                index != props.filter.conditions.length - 1 ? (
                  <TableFilterCell
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {props.filter.operator}
                  </TableFilterCell>
                ) : (
                  ''
                )}
              </React.Fragment>
            ))
          )}
        </div>
      </Popover>

      {(props.filter.isSavedFilter || props.filter.is_saved_filter) &&
        typeof props.filter.id === 'string' && (
          <TableFilterCell isLast>
            <XMarkIcon
              className='w-4 h-4 stroke-gray-500'
              onClick={() => {
                props.onRemove && props.onRemove(props.filter.id)
              }}
            />
          </TableFilterCell>
        )}
      {!props.filter.isSavedFilter && !props.filter.is_saved_filter && (
        <TableFilterCell isLast>
          <XMarkIcon
            className='w-4 h-4 stroke-gray-500'
            onClick={() => props.onRemove && props.onRemove(props.filter.id)}
          />
        </TableFilterCell>
      )}

      {/* <TableFilterCell isLast>
        <XMarkIcon
          className='w-4 h-4 stroke-gray-500'
          onClick={() => props.onRemove && props.onRemove(props.filter.id)}
        />
      </TableFilterCell> */}
    </Row>
  )
}

const TableFilterResults = (props: TableFilterResultsProps) => {
  return (
    <>
      {props.isFetchingMore && (
        <div className={styles.spinner} style={{ marginRight: '3px' }}></div>
      )}
    </>
  )
}

export type AddFilterProps = {
  user: any
  users: any[]
  teams: any[]
  fields: any[]
  filters: CustomFilter[]
  setFilters: Function
  onAddFilter: () => void
  objectType: string
  savedFilters: any[]
  onSetSavedFilters: Function
} & ContainerProps

export type ClearFiltersProps = {
  onClearFilters: () => void
} & ContainerProps

const AddFilter = (props: AddFilterProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <TableFilterCell isIsolated className='!pr-[3px]'>
      <ShadedBackground
        display={isPopoverOpen}
        shadePercentage={0.45}
        zIndex={100}
      />
      <Popover
        {...{
          shading: true,
          shadingColor: 'black',
          isOpen: isPopoverOpen,
          padding: 10,
          containerClassName: 'z-10',
          transformMode: 'relative',
          positions: [props?.filters?.length > 2 ? 'bottom' : 'right'],
          clickOutsideCapture: true,
          onClickOutside: e => {
            e.preventDefault()

            setIsPopoverOpen(false)
          },
          content: (
            <TableFilterBlock
              {...{
                user: props.user,
                users: props.users,
                teams: props.teams,
                fields: props.fields,
                filters: props.filters,
                setFilters: props.setFilters,
                onSubmit: () => setIsPopoverOpen(false),
                objectType: props.objectType,
                savedFilters: props.savedFilters,
                onSetSavedFilters: props.onSetSavedFilters,
              }}
            />
          ),
        }}
      >
        {props.filters.length > 0 ? (
          <PlusIcon
            className='w-4 h-4 stroke-gray-600'
            {...{
              onClick: () => setIsPopoverOpen(!isPopoverOpen),
            }}
          />
        ) : (
          <StartAddingFiltersButton
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            Add Filters
          </StartAddingFiltersButton>
        )}
      </Popover>
    </TableFilterCell>
  )
}

const ClearFilter = (props: ClearFiltersProps) => {
  return (
    <button
      style={{ color: '#5A5A5A' }}
      className='bg-white text-[12px] rounded-md px-2 py-1 leading-tight border-[#e4e4e7] border hover:bg-[#fafafa] whitespace-nowrap'
      {...{
        onClick: props.onClearFilters,
      }}
    >
      Clear Filters
    </button>
  )
}

const SaveFilter = (props: { onSaveFilters: () => void }) => {
  return (
    <button
      style={{ color: '#5A5A5A' }}
      className='bg-white text-[12px] rounded-md px-2 py-1 leading-tight border-[#e4e4e7] border hover:bg-[#fafafa] whitespace-nowrap'
      onClick={props.onSaveFilters}
    >
      Save Filters
    </button>
  )
}

const Divider = tw.div`
  !w-[1px]
  h-[24px]
  bg-gray-100
  mb-0
  mt-0
`

const TableFilterSegmentCollection = tw(
  (props: TableFilterSegmentCollectionProps) => {
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
    const [filterBatchName, setFilterBatchName] = useState('')
    const [orgWide, setOrgWide] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    // Handler to open the Save Filter modal
    const handleSaveFiltersClick = () => {
      setIsSaveModalOpen(true)
    }
    const toast = useToast()

    const submitSaveFilterEnabled = () => {
      if (filterBatchName === '') {
        return false
      }
      return true
    }

    // Handler to save filters with the provided name
    const handleSaveFilters = async () => {
      try {
        // Prepare the filters with named comparators
        const filtersWithNamedComparators = props.filters.map(filter => ({
          ...filter,
          conditions: filter.conditions.map(condition => ({
            ...condition,
            comparator: getComparatorFilterStringFromSymbol(
              condition.comparator,
            ),
          })),
        }))

        // Call the API to save the filters
        const response = await Api.pipelineSavedFilters.create({
          name: filterBatchName,
          filters: filtersWithNamedComparators,
          org_wide: orgWide,
          object_type: props.objectType,
        })

        // Check if the response contains an error
        if ('error' in response) {
          toast({
            variant: 'subtle',
            title: 'Error saving filters',
            description: `${response.error}`,
            position: 'bottom-right',
            status: 'error',
            duration: 10000,
            isClosable: false,
            containerStyle: {
              marginBottom: '40px',
              marginRight: '40px',
            },
          })
        } else {
          // If no error, show success toast
          toast({
            variant: 'subtle',
            title: 'Filters saved successfully',
            description: '',
            position: 'bottom-right',
            status: 'success',
            duration: 5000,
            isClosable: false,
            containerStyle: {
              marginBottom: '40px',
              marginRight: '40px',
            },
          })

          setIsSaveModalOpen(false) // Close the modal after saving
        }
      } catch (error) {
        console.error('Failed to save filters:', error)
      }
    }

    const handleRemoveSavedFilter = (id: string) => {
      const updatedSavedFilters = props.savedFilters.filter(
        filter => filter.id !== id,
      )
      // Find the filters in props.filters that need to be removed based on the saved filter
      const savedFilterToRemove = props.savedFilters.find(
        filter => filter.id === id,
      )
      if (savedFilterToRemove) {
        const updatedFilters = props.filters.filter(
          (filter: CustomFilter) =>
            !savedFilterToRemove.filters.some(
              (savedFilter: any) =>
                savedFilter.key === filter.key &&
                savedFilter.operator === filter.operator,
            ),
        )
        // Update the saved filters and filters in props
        props.onSetSavedFilters(updatedSavedFilters)
        props.setFilters(updatedFilters)
      }
    }

    return (
      <>
        <Row y='center' gap='small' grow>
          {(props.filters.length > 0 || props.savedFilters.length > 0) && (
            <>
              {/* Wrap saved and individual filters in the same container */}
              <Row
                y='center'
                className={`w-[90%] max-w-[90%] overflow-y-scroll ${styles.hideScrollbar}`}
                gap='small'
                style={{ marginBottom: '20px' }} // Add a consistent margin between filters and the divider
              >
                {/* Render the saved filters as compact tags */}
                {props.savedFilters.length > 0 && (
                  <Row y='center' gap='small'>
                    {props.savedFilters.map(
                      (savedFilter: any, index: number) => (
                        <TableFilterSegment
                          key={`savedFilter-${savedFilter.id}-${index}`}
                          filter={{
                            key: savedFilter.name,
                            label: savedFilter.name,
                            isSavedFilter: true,
                            conditions: [],
                            operator: 'and',
                            id: savedFilter.id,
                          }}
                          filterIndex={index}
                          forwardKey={savedFilter.name}
                          onRemove={() =>
                            handleRemoveSavedFilter(savedFilter.id)
                          }
                          fields={props.fields}
                          filters={props.filters}
                          setFilters={props.setFilters}
                          users={props.users}
                          teams={props.teams}
                          user={props.user}
                          filterJoin={props.filterJoin}
                          objectType={props.objectType}
                          displaySavedFilterNameOnly={true}
                          onClick={() => {
                            setIsModalOpen(true)
                          }}
                          savedFilters={props.savedFilters}
                          onSetSavedFilters={props.onSetSavedFilters}
                          listViews={props.listViews}
                          onSaveListView={props.onSaveListView}
                          selectedListViewId={props.selectedListViewId}
                        />
                      ),
                    )}
                  </Row>
                )}

                {/* Render individual filters */}
                {props.filters.length > 0 && (
                  <Row y='center' gap='small'>
                    {props.filters.map(
                      (filter: CustomFilter, index: number) => {
                        if (!filter.isSavedFilter && !filter.is_saved_filter) {
                          return (
                            <TableFilterSegment
                              key={filter.key + '-' + filter.id}
                              filter={filter}
                              filterIndex={index}
                              forwardKey={filter.key}
                              onRemove={props.onRemove}
                              fields={props.fields}
                              filters={props.filters}
                              setFilters={props.setFilters}
                              users={props.users}
                              teams={props.teams}
                              user={props.user}
                              filterJoin={props.filterJoin}
                              objectType={props.objectType}
                              displaySavedFilterNameOnly={false}
                              savedFilters={props.savedFilters}
                              onSetSavedFilters={props.onSetSavedFilters}
                              listViews={props.listViews}
                              onSaveListView={props.onSaveListView}
                              selectedListViewId={props.selectedListViewId}
                            />
                          )
                        }
                        return null
                      },
                    )}
                  </Row>
                )}
              </Row>

              {/* Add a consistent margin to the Divider */}
              <Divider />
            </>
          )}

          <AddFilter
            {...{
              fields: props.fields,
              filters: props.filters,
              setFilters: props.setFilters,
              selectedFilter: undefined,
              onAddFilter: () => {},
              teams: props.teams,
              user: props.user,
              users: props.users,
              objectType: props.objectType,
              savedFilters: props.savedFilters,
              onSetSavedFilters: props.onSetSavedFilters,
            }}
          />
          {props.filters.length > 1 && (
            <FilterCombinationDropdown
              currentListView={props.listViews.find(
                (listView: any) => listView.id === props.selectedListViewId,
              )}
              selectedOption={props.filterJoin}
              onChange={value => {
                props.setFilterJoin(value)
              }}
            />
          )}
          {props.filters.length > 0 && (
            <>
              <ClearFilter
                {...{
                  onClearFilters: () => props.onClearFilters(),
                }}
              />
              <SaveFilter onSaveFilters={handleSaveFiltersClick} />
            </>
          )}
        </Row>
        <Modal
          isOpen={isSaveModalOpen}
          onClose={() => setIsSaveModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent
            minHeight={'250px'}
            width={'360px'}
            borderRadius={'10px'}
          >
            <ModalBody style={{ margin: '2px', marginTop: '2px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title>Save Filters</Title>
              </div>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: '#646464',
                  marginLeft: '2px',
                  marginTop: '4px',
                }}
              >
                {modalTitle(props.objectType)}
              </div>
              <div style={{ marginTop: '40px' }}>
                <Input
                  placeholder='Enter a name for these filters'
                  value={filterBatchName}
                  onChange={e => setFilterBatchName(e.target.value)}
                />
              </div>
              <Flex justifyContent='space-between' mb={5} mt={5} pr='5px'>
                <Text style={{ fontSize: '14px' }}>
                  Available for all users?
                </Text>
                <Checkbox
                  onChange={() => {
                    setOrgWide(!orgWide)
                  }}
                  colorScheme='black'
                  isChecked={orgWide}
                  sx={{
                    '.chakra-checkbox__control': {
                      height: '18px',
                      width: '18px',
                      borderRadius: '3px',
                      borderColor: 'black', // Color of the border
                      _checked: {
                        color: 'white',
                        bg: 'black',
                        borderColor: 'black',
                        svg: {
                          transform: 'scale(1.3)', // Scale up the checkmark
                        },
                      },
                      _hover: {
                        borderColor: 'black', // Border color on hover
                      },
                      _focus: {
                        boxShadow: 'none', // Remove the focus outline
                      },
                    },
                  }}
                />
              </Flex>
            </ModalBody>
            <ModalFooter
              sx={{
                paddingTop: 0,
                borderTop: 0,
                display: 'flex',
                justify: 'space-between',
              }}
            >
              {/* <Button variant="ghost" onClick={() => setIsSaveModalOpen(false)}>
            Cancel
          </Button> */}
              {/* <Button colorScheme="blue" onClick={handleSaveFilters} ml={3}>
            Save
          </Button> */}
              <SaveButton
                disabled={!submitSaveFilterEnabled()}
                onClick={handleSaveFilters}
                style={{ width: '100%' }}
              >
                {'Save'}
              </SaveButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  },
)``

const TableFilterCollection = (props: TableFilterCollectionProps) => {
  const selectedListView = props.listViews?.find(
    (listView: any) => listView.id === props.selectedListViewId,
  )

  // Function to compare two arrays of filters
  const areFiltersEqual = (filters1: any[], filters2: any[]) => {
    if (filters1.length !== filters2.length) return false
    // Compare filters by serializing them
    return JSON.stringify(filters1) === JSON.stringify(filters2)
  }

  // Determine if the filters are the same
  const filtersMatch = selectedListView
    ? areFiltersEqual(selectedListView.filters, props.filters)
    : false

  return (
    <>
      <Row grow y='center' between spacingX='small' spacingY='small'>
        <Row grow className='max-w-[65%]'>
          <TableFilterSegmentCollection {...props} />
        </Row>

        <Row y='center' gap='small'>
          <TableFilterResults
            {...props}
            isFetchingMore={props.isFetchingMore}
          />
        </Row>

        <Flex gap={3} overflowX='auto' whiteSpace='nowrap' paddingLeft='5px'>
          {!filtersMatch && <ListViewUpdateCreateMenu {...props} />}
          <HighlightsMenu {...props} />
          <ListViewOptionsMenu {...props} />
        </Flex>
      </Row>
    </>
  )
}

export default TableFilterCollection

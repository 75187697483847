import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Spacer,
} from '@chakra-ui/react'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'
import { get } from 'lodash'
import React, { HTMLProps, useContext, useEffect, useState } from 'react'
import { StateStore } from '../Store'
import styled from 'styled-components'
import { Users } from 'react-feather'
import Api from '../../lib/api'
import { Select } from 'chakra-react-select'
import FieldMultiSelect from '../Alerts/V2/FieldMultiSelect'
import ProfileMultiSelect from '../ProfileMultiSelect'
import { tw } from '@minupalaniappan/brise'

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
`

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 2px;
  color: #646464;
`
const selectStyles = (width?: string) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: 'none',
    fontSize: '0.875rem',
    // minWidth: '150px',
    width: width ? width : '150.66px', // Make control responsive to parent container's width
    fontWeight: '500',
    minHeight: '36px',
    padding: '0 8px',
    cursor: 'pointer',
    backgroundColor: 'white',
    marginTop: '12px',
    borderRadius: '4px', // Match your border radius
    border: '1px solid #e4e4e7',
    '&:hover': {
      border: '1px solid #e4e4e7!important',
      backgroundColor: '#fafafa',
    },
    '&:focus': { border: '1px solid  #e4e4e7 !important' },
    '&:focus-within': { border: '1px solid  #e4e4e7 !important' },
    '&:active': { border: '1px solid  #e4e4e7 !important' },
    outline: 'none',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0 6px',
    fontSize: '0.875rem',
    fontWeight: '500',
    borderRadius: '10px',
    overflow: 'hidden', // Add this to handle the overflow of elements
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: '#333', // Placeholder text color
    fontSize: '13px', // Placeholder font size
    fontWeight: '500',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    alignItems: 'center',
    position: 'relative',
    bottom: '1px',
    background: 'none !important',
    svg: {
      strokeWidth: '1px !important',
    },
    paddingLeft: '0px',
    paddingRight: '0px',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    border: '.2px solid #E0E0E0',
    borderTop: '0px',
    boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    fontSize: '13px',
    backgroundColor: 'white',
    overflow: 'hidden',
    width: '250px',
    zIndex: 9999999,
  }),
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      background: '#F3F3F3',
      border: '1px solid #EFEFEF',
    }
  },
  option: (provided: any, state: any) => ({
    ...provided,
    color: 'black',
    backgroundColor: state.isSelected ? 'rgb(244, 244, 244)' : 'white',
    '&:hover': {
      backgroundColor: 'rgb(244, 244, 244)',
    },
    fontSize: '13px',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: '#333',
    fontSize: '0.875rem',
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
})

const SaveButton = tw.div<HTMLProps<HTMLDivElement>>`
            cursor-pointer
            rounded-[6px]
            bg-[black]
            text-center
            text-white
            hover:bg-[#282828]
            px-5
            py-2
            text-[14px]
            font-[500]
            ${props => (props.disabled ? `opacity-50 pointer-events-none` : '')}
            `

const DeleteButton = styled.div`
  border-radius: 6px;
  background-color: #ebebeb;
  color: #1a202c;
  width: 150px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 15px;
`

const UpdateProfileFieldAccessDialogue = () => {
  const { state, dispatch } = useContext(StateStore)
  const modalKey = get(state, 'modal.key', '') as string
  let isOpen = ['updateProfileFieldAccess'].includes(modalKey)
  const [objects, setObjects] = useState<Array<any>>([])
  const [profiles, setProfiles] = useState<Array<any>>([])
  const [fields, setFields] = useState<Array<any>>([])
  const [selectedObject, setSelectedObject] = useState('')
  const [selectedFields, setSelectedFields] = useState([])
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [selectedAction, setSelectedAction] = useState('')
  const toast = useToast()
  const user = get(state, 'user')

  const baseRef = get(state, 'dialogue.rootRef.ref')

  const onClose = () => {
    dispatch({
      type: 'TOGGLE_MODAL',
      payload: {
        key: '',
        data: {},
      },
    })
  }

  useEffect(() => {
    Api.salesforce.objects.getObjects({}).then(({ objects }) => {
      setObjects(objects)
    })

    Api.salesforce.profiles.getProfiles({}).then(({ profiles }) => {
      setProfiles(profiles)
    })
  }, [])

  useEffect(() => {
    if (!!selectedObject) {
      Api.salesforce.objects.objectName.fields
        .getFields({ objectName: selectedObject })
        .then(({ fields }) => {
          if (fields === undefined) {
            return
          }

          setFields(fields)
        })
    }
  }, [selectedObject])

  const handleFieldSelect = (field: any) => {
    setSelectedFields((prevFields: any) => {
      if (prevFields.includes(field)) {
        return prevFields.filter((f: any) => f !== field)
      }
      const updatedFields = [...prevFields, field]
      return updatedFields
    })
  }

  const handleProfileSelect = (id: any) => {
    setSelectedProfiles((prevProfiles: any) => {
      if (prevProfiles.includes(id)) {
        return prevProfiles.filter((p: any) => p !== id)
      }
      const updatedProfiles = [...prevProfiles, id]
      return updatedProfiles
    })
  }

  const handleSubmit = () => {
    Api.salesforce.profiles
      .updateFieldAccess({
        object: selectedObject,
        fields: selectedFields,
        profiles: selectedProfiles,
        permissions_action: selectedAction,
      })
      .then(() => {
        onClose()
        toast({
          description: `Field permissions updated for ${selectedProfiles.length} profiles`,
          status: 'success',
          duration: 5000,
          position: 'top',
        })
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      portalProps={{
        containerRef: baseRef,
      }}
      closeOnEsc
      closeOnOverlayClick
    >
      <ModalOverlay />

      <ModalContent
        minHeight={'275px'}
        width={'660px'}
        borderRadius={'10px'}
        style={{ minWidth: '600px' }}
      >
        <ModalCloseButton />
        <ModalBody>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Users size={26} />
            <Title style={{ marginLeft: '16px', marginBottom: '2px' }}>
              Update Profile Field Access
            </Title>
          </div>

          <Subtitle>Update field access for user profiles in bulk</Subtitle>
          <div style={{ marginTop: '30px', marginBottom: '15px' }}>
            <Table>
              <Tbody>
                <Tr>
                  <Td
                    style={{
                      fontSize: '13px',
                      color: '#1E2430',
                      fontWeight: 500,
                      width: '100px',
                      border: 'none',
                      paddingLeft: '0px',
                    }}
                    py={0}
                  >
                    Object
                  </Td>
                  <Td>
                    <Select
                      chakraStyles={selectStyles()}
                      onChange={(e: any) => {
                        setSelectedObject(e.value)
                      }}
                      isDisabled={false}
                      placeholder='Select object'
                      options={
                        objects?.map((obj: any) => ({
                          label: obj.label,
                          value: obj.name,
                        })) || []
                      }
                      isSearchable={true}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    style={{
                      fontSize: '13px',
                      color: '#1E2430',
                      fontWeight: 500,
                      width: '100px',
                      border: 'none',
                      paddingLeft: '0px',
                    }}
                    py={0}
                  >
                    Fields
                  </Td>
                  <Td>
                    <FieldMultiSelect
                      fields={fields || []}
                      selections={selectedFields}
                      onSelect={handleFieldSelect}
                      placeholder='Fields to update access for'
                      menuWidth={'370px'}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    style={{
                      fontSize: '13px',
                      color: '#1E2430',
                      fontWeight: 500,
                      width: '150px',
                      border: 'none',
                      paddingLeft: '0px',
                    }}
                    py={0}
                  >
                    Profiles to update
                  </Td>
                  <Td>
                    <ProfileMultiSelect
                      profiles={profiles || []}
                      selections={selectedProfiles}
                      onSelect={handleProfileSelect}
                      placeholder='Profiles to update'
                      menuWidth={'370px'}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    style={{
                      fontSize: '13px',
                      color: '#1E2430',
                      fontWeight: 500,
                      width: '100px',
                      border: 'none',
                      paddingLeft: '0px',
                    }}
                    py={0}
                  >
                    Action
                  </Td>
                  <Td>
                    <Select
                      chakraStyles={selectStyles('370px')}
                      onChange={(e: any) => {
                        setSelectedAction(e.value)
                      }}
                      isDisabled={false}
                      placeholder='Select permissions to update'
                      options={[
                        {
                          value: 'add_read',
                          label: 'Add read permission',
                        },
                        {
                          value: 'add_all',
                          label: 'Add read and edit permissions',
                        },
                        {
                          value: 'remove_edit',
                          label: 'Remove edit permission',
                        },
                        {
                          value: 'remove_all',
                          label: 'Remove read and edit permissions',
                        },
                      ]}
                      isSearchable={true}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <SaveButton
            onClick={() => {
              handleSubmit()
            }}
            style={{ width: '100%' }}
            disabled={
              !selectedObject ||
              selectedFields?.length === 0 ||
              selectedProfiles?.length === 0 ||
              !selectedAction
            }
          >
            {'Update Profiles'}
          </SaveButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpdateProfileFieldAccessDialogue

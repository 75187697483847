export type ShadedBackgroundProps = {
    display: boolean,
    shadePercentage: number, // 0.0-1.0
    zIndex: number,
  } 

  const ShadedBackground = (props: ShadedBackgroundProps) => {
  
    return (
        <div style={{
            position:'fixed',
            top:0,
            left:0,
            width: '100vw',
            height: '100vh',
            backgroundColor: `rgba(0,0,0,${props.shadePercentage})`,
            zIndex: props.zIndex,
            display: props.display ? 'block' : 'none',
      }}></div>
  )
}

export default ShadedBackground